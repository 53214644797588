import React, {useContext, useEffect, useRef} from 'react'

import {OptionsContext} from '../../../App.js'
import Sizes from '../size/Sizes.js'
import OptionGroup from '../group/Group.js'
import {configName} from '../../../helpers/constants.js'
import './panel.css'
import Footer from './Footer.js'
import ContactForm from './ContactForm.js'

export default function Panel() {

    const { config, tabs, setTabs, tooltipRef, showOptionPanel, setShowOptionPanel, priceTable } = useContext( OptionsContext )

    if (!priceTable) return null

    const refPanel = useRef()
    const refFooter = useRef()
    const refContactForm = useRef()

    let offsetScrollTooltip = 0
    let lastScrollY = 0

    function handleOptionPanelScroll( event ) {
        event.preventDefault()
        const scrollY = event.target.scrollTop

        const scrollDir = scrollY - lastScrollY > 0 ? 'down' : 'up'

        lastScrollY = scrollY

        if ( Math.abs( scrollY - offsetScrollTooltip ) > 100 ) {
            tooltipRef.current.classList.add('hide')
            offsetScrollTooltip = scrollY
        }
    }

    function handleBodyClick( event ) {
        if ( ! ( event.target.closest('.option_small_tooltip') || event.target.closest('.checkbox__tooltip_button') ) ) {
            tooltipRef.current.classList.add('hide')
        }
    }

    /**
     * Mouse Body Click,
     * Hide the text that was before loading the configurator
     */
    useEffect(() => {
        const preloadTextHtml = document.getElementById('preload_text')
        if (preloadTextHtml) {
            preloadTextHtml.style.display = 'none'
        }

        document.body.addEventListener('click', handleBodyClick)

        return () => { document.body.removeEventListener('click', handleBodyClick) }
    }, [])

    /**
     * При переключенні на таби, меню скролиться вверх
     */
    useEffect(() => {
        if (refPanel.current) {
            refPanel.current.scrollTop = 0
        }
    }, [tabs])

    function handleFormSubmit(e) {
        e.preventDefault()
        refContactForm.current.style.display = 'flex'

        const prevChecked = tabs.find(tab => tab.checked)
        const newTabs = tabs.map((t, i) => {
            t.checked = i === 0
            t.prevChecked = t.id === prevChecked.id
            return t
        })
        setTabs( newTabs )

        if (window.innerWidth < 1180 && window.innerWidth > 780) {
            setShowOptionPanel(false)
        }
    }

    const optionsSortByTab = tabs.map(() => [])

    Object.keys(config)
        .map(key => config[key])
        .sort((a, b) => a.value.id - b.value.id)
        .forEach( item => {
            const element = item.value.name === configName.size
                ? <Sizes key={ item.value.name } configGroup={ item } />
                : <OptionGroup key={ item.value.name } configGroup={ item } />


            optionsSortByTab[item.value.tab].push(element)
        })

    const tab = tabs.find(t => t.checked)

    /**
     * @author OlBor
     * @time   18/02/2025 21:36:36
     *
     * @note При зміні з Interieur - Holzart - Astige Zirbe на іншу опцію
     * змінити розмір ширини моделі до 250 см,
     * так як тільки на Astige Zirbe ширина в Морени 270 см
     * а в інших сягає тільки до 250 см
     */
    // useEffect(() => {
    //     const modelName = config[configName.model].value.optionsSelected[0].name
    //     const typeWoodInName = config[configName.typeWood2].value.optionsSelected[0].name
    //
    //     if (modelName === 'morena') {
    //         if (typeWoodInName !== 'zirbe') {
    //             if (config[configName.size].value.width > 2.5) {
    //                 config[configName.size].setValue(value => {
    //                     value.width = 2.5
    //                     return {...value}
    //                 })
    //             }
    //         }
    //     }
    //
    // }, [config[configName.typeWood2].value])


    /**
     * @author OlBor
     * @time   18/02/2025 21:54:38
     *
     * @note
     *    При моделі Athen не показувати опцію Interieur - Bankaufstellung - Layout D
     */
    useEffect(() => {
        const modelName = config[configName.model].value.optionsSelected[0].name
        const bench_layout = config[configName.benchLayout].value.options.find(o => o.name === 'layout_d')

        bench_layout.visible = modelName !== 'athen'

    }, [config[configName.model].value])

    return (
        <>
            <div id="options_panel" className={ showOptionPanel ? 'show' : 'hide' }>
                <form onSubmit={ handleFormSubmit }>
                    <div className="options_panel__in" onScroll={ handleOptionPanelScroll } ref={ refPanel }>
                        <h1 className="options_panel__title"><span>{ tab.title }</span></h1>
                        <ul className="options_panel__options_list">
                            { optionsSortByTab[tab.id] }
                        </ul>
                    </div>

                    <div className="options_footer show" ref={refFooter}>
                        { <Footer /> }
                    </div>
                </form>
            </div>

            <div className="contact_form" ref={refContactForm} style={{ display: 'none' }}>
                { <ContactForm contactFormRef={refContactForm.current} /> }
            </div>
        </>
    )
}
