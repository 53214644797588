import * as Papa from 'papaparse'
import {replaceStr} from './functions.js'
import {configName} from './constants.js'

async function _data(dir, files, dataProcessor ) {
    const results = {}

    for ( const file of files ) {
        try {
            const response = await fetch( dir + file )

            if ( ! response.ok ) {
                throw new Error(`HTTP error! status: ${ response.status }`)
            }

            const responseText = await response.text()

            const result = Papa.parse( responseText )

            dataProcessor(results, result, file)

        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return results;
}

async function getPriceTable() {
    return await _data('/assets/data/price/', [
        'venus-fichte.csv',
        'venus-zirbe.csv',
        'athen-fichte.csv',
        'athen-zirbe.csv',
        'kristall-fichte.csv',
        'kristall-zirbe.csv',
        'morena.csv',
        'morena-fichte.csv',
        'morena-zirbe.csv',
        'glastyp.csv',
    ],(results, result, fileName) => {
        const [name, suffix] = fileName.split('.')[0].split('-')

        if (name === 'glastyp') {
            results.glastyp = {}
            const columns = result.data[0]
            result.data.forEach((array, i) => {
                if (i > 0) {
                    let model
                    array.forEach((value, i) => i === 0
                        ? model = results.glastyp[value] = {}
                        : model[columns[i]] = +value
                    )
                }
            })

        } else {
            results[name] = {...results[name], [suffix ? suffix : 'rest']: result.data}
        }
    })
}

async function getOptionsTables() {
    const result = await _data('/assets/data/options/', [ 'options.csv' ], (results, result, fileName) => {
        results.data = result.data
    })
    return result.data
}


// OPTIONS
function getOptions( optionsTables ) {
    if ( !optionsTables ) return

    const result = []

    const getIndex = (table, start) => table.findIndex( (row, i) => i >= start && row[0] === '' )
    let idGroup = 0

    for ( let index = getIndex( optionsTables, 0 ) + 1; index < optionsTables.length; index++ ) {
        if ( optionsTables[index][0] === '' ) {
            continue
        }

        const tab = optionsTables[ index ][9].trim(), // tab number
              parent = optionsTables[ index ][3] === '' ? null : optionsTables[ index ][3].trim(),
              indexStartOption = index,
              keyOption = optionsTables[ index ][2], // slug
              options = []

        if ( tab === '' ) continue

        const optionGroup = {
            id: idGroup++,
            title: optionsTables[ index ][0].trim(),
            name: optionsTables[ index ][2].trim(),
            parentGroup: parent,
            nameEn: optionsTables[ index ][4].trim(),
            tab: tab,
            info_title: optionsTables[ index ][11],
            info_content: optionsTables[ index ][12],
            info_img: optionsTables[ index ][13],
            optionsSelected: []
        }


        // OptionS

        while ( optionsTables[ ++index ][0] ) {
            const data = optionsTables[ index ],
                dir = data[6] ? '/' + data[6].trim() : '',
                ext = data[8] ? data[8].trim() : 'png',
                imgSrc = data[7]
                    ? `/assets/images/options/${ keyOption }${ dir }/${ data[7].trim() }.${ ext }` : '',
                name = data[2] ? data[2].trim() : replaceStr( data[0] )


            // Additional Options

            let additionalOptions = null

            if ( data[4] ) {
                const [optName, optValue] = data[4].split(':')
                const options = optValue.split(',').map((o, i, arr) => {
                    const title = o.trim().replace('+', '')
                    return {
                        title,
                        name: replaceStr(title),
                        _available: true,
                        available: true,
                        selected: arr.length === 1
                            || o.trim()[0] === '+'
                            || (arr.every(e => e.trim()[0] !== '+') && arr.length - 1 === i),
                    }
                } )

                additionalOptions = {
                    groupName: keyOption,
                    optionName: name,
                    title: optName.trim(),
                    name: replaceStr(optName),
                    options: options,
                }
            }


            // Option

            const option = {
                id: index - indexStartOption - 1,
                title: data[0].trim(),
                name: name,
                img: imgSrc,
                additional_options: additionalOptions,
                parentGroup: parent,
                parentNames: parent ? data[3].split(',').map(a => a.trim()) : null,
                group: keyOption,
                info_title: data[11],
                info_content: data[12],
                info_img: data[13],
                visible: true,
                _available: data[10] === '',
                available: data[10] === '',
            }

            if ( keyOption === configName.size ) {
                option.value = Number( data[5].trim() )
            } else {
                option.price = data[5].trim().replace(',', '.')
            }

            options.push( option )

            if ([ 1, '1', 'default' ].includes( data[1].trim() )) {
                optionGroup.optionsSelected.push( option )
            }
        }

        optionGroup.options = options

        result.push( optionGroup )

        index = getIndex( optionsTables, index )
    }

    return result
}

export {
    getPriceTable,
    getOptionsTables,
    getOptions
}